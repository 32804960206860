button {
  cursor: pointer;
}

.button-wrapper {
  display: contents;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: calc(100% - 5px - 5px);
  margin: 5px;
  padding: 10px 15px;
  border: none;
  border-radius: var(--rounded);
  background: var(--primary);
  color: var(--background);
  text-align: center;
  font: inherit;
  font-family: var(--heading);
  font-weight: var(--semi-bold);
  text-decoration: none;
  vertical-align: middle;
  appearance: none;
  transition: background var(--transition), color var(--transition);
}

.button:hover {
  background: var(--text);
  color: var(--background);
}

.button[data-style="bare"] {
  padding: 5px;
  background: none;
  color: var(--primary);

  &:hover {
    color: var(--text);
  }
}

.button[data-flip] {
  flex-direction: row-reverse;
}
